
<template>
  <div class="email-text">
    <div class="card-title mt-8">
      <h2>Text Message Inbox</h2>
    </div>
    <vx-card>
      <!-- Sidebar -->
      <div
        class="email-sidebar"
        v-bind:class="{ hidden: isHidden, 'lg:block': isBlock }"
      >
        <vs-button
          color="danger"
          type="flat"
          icon-pack="feather"
          icon="icon-x"
          @click="(isHidden = !isHidden), (isBlock = !isBlock)"
          class="visible lg:hidden close-icon"
        ></vs-button>
        <div class="domain-info">
          <!-- Date Picker -->
          <date-range-picker
            class="pt-0 review-date"
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="
              (newFromDate) => {
                fromDate = newFromDate;
              }
            "
            @inputToDate="
              (newToDate) => {
                toDate = newToDate;
              }
            "
          ></date-range-picker>
          <vs-button
            type="filled"
            @click="updateData()"
            class="small-btn w-full mb-0 bg-actionbutton"
            >{{ LabelConstant.buttonLabelRunQuery }}</vs-button
          >
        </div>
        <ul>
          <li @click="isInboxItems = true,isSentItems = false,getListInboxTextMessages()">
            <router-link to v-bind:class="{ active: isInboxItems }">
              <feather-icon icon="MailIcon" />Inbox
            </router-link>
          </li>
          <li @click="isInboxItems = false,isSentItems = true,getListSentTextMessages()">
            <router-link to v-bind:class="{ active: isSentItems }">
              <feather-icon icon="SendIcon" />Sent Item
            </router-link>
          </li>
        </ul>
      </div>

      <div class="text-msg-inbox">
        <!-- CSV - Excel export data -->
        <div class="text-mg-filter">
          <div class="actions-button">
            <vs-button
              type="filled"
              icon-pack="feather"
              icon="icon-more-vertical"
              class="mr-4 visible lg:hidden bg-actionbutton"
              @click="(isHidden = !isHidden), (isBlock = !isBlock)"
            ></vs-button>
            <!--   <vx-tooltip color="secondary" text="CSV" class="float-left">
              <vs-button color="secondary" type="filled">
                <img src="../assets/images/csv.svg" />
              </vs-button>
            </vx-tooltip>-->
            <vx-tooltip color="actionbutton" text="EXCEL" class="float-left">
              <vs-button class="bg-actionbutton" type="filled">
                <!--    <img src="../assets/images/excel.svg"> -->
                <download-excel
                  class="btn btn-default"
                  :data="excelData"
                  :exportFields="excelFields"
                  worksheet="Text Message Inbox"
                  name="Text Message Inbox.xls"
                >
                  <img src="@/assets/images/excel.svg" />
                </download-excel>
              </vs-button>
            </vx-tooltip>
          </div>
          <vs-input
            icon-pack="feather"
            icon="icon-search"
            placeholder="Search"
            v-model="searchText"
            class="is-label-placeholder mt-0"
          />
        </div>

        <!-- Inbox Message -->
        <div
          class="flex flex-wrap inbox-item w-full"
          v-if="isInboxItems || ismessageDetail"
        >
          <VuePerfectScrollbar class="scroll-area" :settings="settings">
            <div
              class="msg-area"
              v-for="(item, index) in inboxItemList"
              :key="index"
              @click="ismessageDetail = true,textMessagesFrom = item.To,textMessagesTo = item.From,getMessagesForPhoneNumber()"
            >
              <div class="msg-no">
                <span>
                  <b>From:</b>
                  {{ item.From }}
                </span>
                <span>
                  <b>To:</b>
                  {{ item.To }}
                </span>
              </div>
              <span class="date">{{ item.Date_Created }}</span>
              <p>{{ item.Msg }}</p>
              <vs-chip color="secondary" v-if="item.Msg_Status == 0">New</vs-chip>
              <vs-chip color="primary" v-if="item.Msg_Status == 1">Read</vs-chip>
              <vs-chip color="success" v-if="item.Msg_Status == 2">Responded</vs-chip>
              <vs-button
                type="filled"
                icon-pack="feather"
                icon="icon-eye"
                class="float-right"
                color="primary"
              ></vs-button>
            </div>
          </VuePerfectScrollbar>
          <div
            class="empty-msg flex items-center"
            v-if="inboxItemList.length < 1"
          >
            <div class="empty-icon">
              <feather-icon icon="MessageSquareIcon" />
            </div>
            <h4>No Message Available</h4>
          </div>
        </div>

        <!-- Sent Message -->
        <div
          class="flex flex-wrap inbox-item w-full"
          v-if="isSentItems || ismessageDetail"
        >
          <VuePerfectScrollbar class="scroll-area" :settings="settings">
            <div
              class="msg-area"
              v-for="(item, index) in sentItemList"
              :key="index"
              @click="ismessageDetail = true,textMessagesFrom = item.From,textMessagesTo = item.To,getMessagesForPhoneNumber()"
            >
              <div class="msg-no">
                <span>
                  <b>From:</b>
                  {{ item.From }}
                </span>
                <span>
                  <b>To:</b>
                  {{ item.To }}
                </span>
              </div>
              <span class="date">{{ item.Date_Created }}</span>
              <p>{{ item.Msg }}</p>
              <vs-chip color="success" v-if="item.Msg_Status == 0"
                >Sent</vs-chip
              >
              <vs-button
                type="filled"
                icon-pack="feather"
                icon="icon-eye"
                class="float-right"
                color="primary"
              ></vs-button>
            </div>
          </VuePerfectScrollbar>
          <div
            class="empty-msg flex flex-col items-center"
            v-if="sentItemList.length < 1"
          >
            <feather-icon icon="MessageSquareIcon" />
            <h4>No Message Available</h4>
          </div>
        </div>

        <!-- More Details -->
        <div
          class="msg-more-details"
          v-bind:class="{ visibleh: !ismessageDetail }"
        >
          <!-- Card Title -->
          <div class="card-title justify-start">
            <h2>
              <span @click="ismessageDetail = false, clearSentMessageValidationError()">
                <feather-icon icon="ArrowLeftIcon" /> </span
              >Text Message Details
            </h2>
          </div>
          <!-- Msg Info -->
          <h4 class="msg-info-title">
            Text Messages from {{ textMessagesTo }}
          </h4>
          <VuePerfectScrollbar
            class="msg-info"
            :settings="settings"
            id="chatScroll"
          >
            <div class="text-details" v-if="messageDetails.length > 0">
              <div class="inner-msg-details">
                <ul>
                  <li
                    v-for="(messageDetail, index) in messageDetails"
                    :key="index"
                  >
                    <div
                      v-bind:class="{
                        'form-msg': messageDetail.Direction == 'in',
                        'to-msg bg-secondary-gradient': messageDetail.Direction == 'out',
                      }"
                    >
                      <span>{{ messageDetail.Date_Created }}</span>
                      <p>{{ messageDetail.Msg }}</p>
                    </div>
                  </li>
                </ul>
                <vs-button
                  radius
                  color="grey"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-refresh-ccw"
                  class="refresh-btn"
                  @click="getMessagesForPhoneNumber()"
                ></vs-button>
              </div>
            </div>
            <div
              class="empty-msg flex flex-col items-center"
              v-if="messageDetails.length < 1"
            >
              <feather-icon icon="MessageSquareIcon" />
              <h4>No Conversation Available</h4>
            </div>
          </VuePerfectScrollbar>
          <!-- Msg Send -->
          <div class="msg-details">
            <h4>Send Text Message to {{ textMessagesTo }}</h4>
            <div class="text-type-send">
              <form data-vv-scope="sentMessageForm">
                <vs-textarea
                  placeholder="Message To Send"
                  v-model="messageToSendText"
                  v-validate="'required'"
                  name="messageToSendText"
                />
                <span class="text-danger text-sm error-msg">{{
                  errors.first("sentMessageForm.messageToSendText")
                }}</span>
              </form>
              <vs-button
                class="bg-actionbutton"
                type="filled"
                @click="sendTextMessage()"
                >{{ LabelConstant.buttonLabelSend }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import DateRangePicker from "../../components/filter/DateRangePicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Validator } from "vee-validate";
/* export excel */
import JsonExcel from "vue-json-excel";

const dict = {
  custom: {
    messageToSendText: {
      required: "A Message is required",
    },
  },
};

Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    DateRangePicker,
    VuePerfectScrollbar,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      searchText: null,
      isHidden: true,
      isBlock: true,

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Inbox message list
      inboxItemList: [],
      inboxList: [],
      isInboxItems: true,
      //Sent message list
      sentItemList: [],
      isSentItems: false,

      //Selected message details
      messageDetails: [],

      //Text Messages from number
      textMessagesFrom: null,
      //Send Text Message to number
      textMessagesTo: null,
      // Sent message text
      messageToSendText: null,
      ismessageDetail: false,
      //Export excel
      excelFields: {
        From: "From",
        To: "To",
        Message: "Message",
        Status: "Status",
        Date: "Date",
      },
      excelData: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  watch: {
    searchText(val) {
      this.searchFilter(val);
    },
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.getListInboxTextMessages();
    this.getListSentTextMessages();
  },
  methods: {
    scrollToBottom() {
      var objDiv = document.getElementById("chatScroll");
      objDiv.scrollTop = objDiv.scrollHeight;
      /*    this.$nextTick(() => {
                this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight
          }); */
    },

    //On clicking Run Query button will update data
    updateData() {
      this.getListInboxTextMessages();
      /* this.getListSentTextMessages(); */
    },
    //Clear sent message validation error
    clearSentMessageValidationError() {
      this.messageToSendText = null;
      setTimeout(() => {
        this.errors.clear("sentMessageForm");
      }, 20);
    },

    //Get Inbox message
    getListInboxTextMessages() {
      let getListInboxTextMessagesParams = {
        Date_Start: this.fromDate,
        Date_End: this.toDate,
        Inbox: "inbox",
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackTextInbox/ListTextMessages",
          getListInboxTextMessagesParams
        )
        .then((response) => {
          let data = response.data;
          this.inboxItemList = data;
          this.inboxList = data;
          this.excelData = this.filterExport();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Get Sent message
    getListSentTextMessages() {
      let getListInboxTextMessagesParams = {
        Date_Start: this.fromDate,
        Date_End: this.toDate,
        Inbox: "sent",
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackTextInbox/ListTextMessages",
          getListInboxTextMessagesParams
        )
        .then((response) => {
          let data = response.data;
          this.sentItemList = data;
          this.excelData = this.filterExport(true);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Get message details
    getMessagesForPhoneNumber() {
      let getMessagesForPhoneNumberParams = {
        Phone_Number: this.textMessagesFrom,
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackTextInbox/GetMessagesForPhoneNumber",
          getMessagesForPhoneNumberParams
        )
        .then((response) => {
          let data = response.data;
          this.updateMessageStatusToReadForPhoneNumber(this.textMessagesFrom);
          this.messageDetails = data;
          setTimeout(() => {
            this.scrollToBottom();
          }, 200);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Update status to Read for phone number
    updateMessageStatusToReadForPhoneNumber(phoneNumber) {
      let updateMessageStatusToReadForPhoneNumberParams = {
        Phone_Number: phoneNumber,
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackTextInbox/UpdateMessageStatusToReadForPhoneNumber",
          updateMessageStatusToReadForPhoneNumberParams
        )
        .then(() => {
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Send message
    sendTextMessage() {
      let sendTextMessageParams = {
        From: this.textMessagesFrom,
        To: this.textMessagesTo,
        Msg: this.messageToSendText,
      };
      this.$validator.validateAll("sentMessageForm").then((result) => {
        if (result) {
          this.$vs.loading();
          this.axios
            .post(
              "/ws/CallTrackTextInbox/SendTextMessage",
              sendTextMessageParams
            )
            .then((response) => {
              let data = response.data;
              this.clearSentMessageValidationError();
              this.messageDetails = data;
              this.$vs.notify({
                title: "Success",
                text: "Your has been sent successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    filterExport(sent = false) {
      let data = [];
      let localListingData = !sent ? this.inboxItemList : this.sentItemList;

      for (var i = 0; i < localListingData.length; i++) {
        data.push({
          From: localListingData[i].From,
          To: localListingData[i].To,
          Message: localListingData[i].Msg,
          Status:
            sent == false
              ? localListingData[i].Msg_Status == 0
                ? "New"
                : localListingData[i].Msg_Status == 1
                ? "Read"
                : localListingData[i].Msg_Status == 2
                ? "Responded"
                : ""
              : localListingData[i].Msg_Status == 0
              ? "Sent"
              : "",
          Date: localListingData[i].Date_Created,
        });
      }
      return data;
    },
    searchFilter(string) {
      let str = string.toLowerCase();
      let searchInbox = [];
      this.inboxList.filter((record) => {
        let Msg = record.Msg.toLowerCase();
        if (
          Msg.includes(str) ||
          record.From.includes(str) ||
          record.To.includes(str)
        ) {
          searchInbox.push(record);
        }
      });
      this.inboxItemList = searchInbox;
    },
    // capSentence(text) {
    //   let wordsArray = text.toLowerCase();
    //   return wordsArray;
    //   console.log(wordsArray);
    //   // let capsArray = wordsArray.map(word => {
    //   //   return word[0].toLowerCase() + word.slice(1);
    //   // });

    //   // return capsArray.join(" ");
    // }
  },
};
</script>
<!-- Remaning task for Text Msg Inbox
  - Searching functionality.
-->
